import moment from 'moment';
import axios from 'axios';

const config = require('config');

export default {
  async getStoreList(context, { brand, region = '' }) {
    try {
      let link = `${process.env.API_BASE_URL}/smartphone/service-center/${brand}?availableWalkin=1`;
      if (region) link = link + `&region=${region}`;
      let response = await axios.get(link);
      const { data } = response.data;
      context.commit('updateStoreList', data);
      return data;
    } catch (error) {
      const errorResponse = error.response;
      return errorResponse;
    }
  },
  // * STAN API START HERE
  async searchPolicyV2(context, { number, partnerCode, type }) {
    return await this.$axios.get('/v2/policies/search', {
      params: {
        number,
        partnerCode,
        type,
      },
    });
  },
  async addPolicyV2(context, { number }) {
    const response = await this.$axios.post('/v2/policies/add', {
      number,
    });
    return response;
  },
  async addPolicyIdempotent(context, payload) {
    const response = await this.$axios.post('/v2/policies/add', payload);
    return response;
  },
  async getPoliciesV2(context, { page, limit, parentCategory }) {
    const { data: updatePolicies } = await this.$axios.get(
      '/v2/policies/list',
      {
        params: {
          page: page,
          limit: limit,
          parent_category: parentCategory,
        },
      }
    );
    const policies = context.getters['getPolicies'];

    if (policies) {
      if (updatePolicies.meta.page > policies.meta.page) {
        updatePolicies.data = policies.data.concat(updatePolicies.data);
      }
    }
    context.commit('updatePolicies', updatePolicies);
    return updatePolicies.data || [];
  },
  async getPolicyDetailV2(context, { policyNumber, callback = () => null }) {
    const { data } = await this.$axios.get(
      `/v2/policies/${policyNumber}/detail`
    );
    context.commit('updatePolicy', data.data);
    return callback();
  },
  async getBenefitByProductCode(context, { path }) {
    const { data } = await this.$axios.get(
      `/v2/products/${path}`
    );
    return data.data.benefits;
  },
  async fetchClaimDetailV2(context, { claimNumber }) {
    const { data } = await this.$axios.get(`/v2/claims/${claimNumber}/detail`);
    context.commit('updateClaim', data.data);
    return data.data;
  },
  async fetchClaimHistoriesV2(context, { claimNumber }) {
    const { data } = await this.$axios.get(
      `/v2/claims/${claimNumber}/histories`
    );
    context.commit('updateHistoriesClaim', data.data);
  },
  async fetchClaimAndHistories({ dispatch }, { claimNumber }) {
    return Promise.all([
      dispatch('fetchClaimDetailV2', { claimNumber }),
      dispatch('fetchClaimHistoriesV2', { claimNumber }),
    ]);
  },
  async generateClaimForm(
    context,
    { policyNumber, productCode, benefitCode, productCategory, timeZone }
  ) {
    const response = await this.$axios.post(
      '/v2/claims/internal/benefit_form',
      {
        policy_number: policyNumber,
        product_code: productCode,
        benefit_code: benefitCode,
        product_category: productCategory,
        timezone: timeZone,
      }
    );
    context.commit('updateClaimForm', response.data.data);
  },
  async requestPresignClaim(context, { documents }) {
    return await this.$axios.post(`/v2/claims/document/presign`, documents);
  },
  async sendClaimForm(context, { data }) {
    return await this.$axios.post(`/v2/claims/create`, data);
  },
  async updateClaim(context, { data }) {
    return await this.$axios.put(`/v2/claims/update`, data);
  },
  // * STAN API END HERE
  async getCategoryProducts() {
    const { data } = await this.$axios.get('/policies/category');
    return data.data;
  },
  async getPolicyData(context, { page }) {
    const startDate = moment().add(-1, 'years').format('YYYY-MM-DD');
    const endDate = moment()
      .add(+6, 'months')
      .format('YYYY-MM-DD');

    const codeCountry = await context.rootGetters['core/getCountryCode'];
    let APIPath = '/policies';
    const listCountry = ['VN', 'TH'];
    if (listCountry.includes(codeCountry))
      APIPath = `${APIPath}/list?&page=${page}&country=${codeCountry}`;
    else
      APIPath = `${APIPath}?sortDirection=desc&limit=7&page=${page}&startDate=${startDate}&endDate=${endDate}`;
    try {
      const { data } = await this.$axios.get(APIPath);
      const response = data;
      const policies = context.getters['getPolicies'];

      if (policies) {
        if (response === policies) return true;
        if (response.meta.page > policies.meta.page) {
          response.data = policies.data.concat(response.data);
        }
        context.commit('updatePolicies', response);
      } else {
        context.commit('updatePolicies', response);
      }
      return response;
    } catch (error) {
      const errorResponse = error.response;
      const errorData = (errorResponse || {}).data;
      return errorData;
    }
  },
  async getPolicyDetail(
    context,
    { policyType, policyNumber, callback = () => null }
  ) {
    let type = policyType;

    switch (type) {
      case 'flight':
        type = 'travel';
        break;
      case 'healthDBD':
        type = 'dbd';
        break;
      case 'accommodation':
        type = 'accomm';
        break;
      case 'electronic':
        type = 'electronic';
        break;
      default:
        break;
    }
    try {
      let link;
      switch (type) {
        case 'dbd':
          link = `/health/policies/${type}/${policyNumber}`;
          break;
        case 'accomm':
          link = `/${type}/policies/${policyNumber}`;
          break;
        case 'hcp-partnership':
          link = `/health/v1.0/policies/hcp/${policyNumber}`;
          break;
        case 'pa+-partnership':
          link = `/health/v1.0/policies/pa+/${policyNumber}`;
          break;
        case 'accident-partnership':
          link = `/health/v1.0/policies/pa/${policyNumber}`;
          break;
        case 'tropical disease-partnership':
          link = `/health/v1.0/policies/tropical-disease/${policyNumber}`;
          break;
        case 'vehicle':
        case 'BIKE':
        case 'life':
        case 'health':
        case 'hcp':
        case 'car':
        case 'property':
        case 'motor':
        case 'individual':
        case 'tropical-disease':
          link = `/policies/${policyNumber}`;
          break;
        default:
          link = `/${type}/policies/${policyNumber}`;
          break;
      }
      const { data } = await this.$axios.get(link);
      const response = data && data.data ? data.data : data;
      context.commit('updatePolicy', response);
      return callback();
    } catch (error) {
      throw error;
    }
  },
  /*
   *  @param {string} partnerId: partner ID to search policy according to specific partner
   *  @param {string} isCustomer: flag for BE to return array of object when search policy (to distinguish between q.app and dashboard)
   *
   */
  async searchPolicy(
    context,
    {
      type,
      pnr,
      channel = '',
      partnerId = '',
      isCustomer = '',
      additionalType = '',
      callback,
    }
  ) {
    try {
      let link;
      if (type === this.app.i18n.t('add_policy_option1')) {
        link = '/train/policies/booking-code/';
      } else if (type === this.app.i18n.t('add_policy_option3')) {
        if (additionalType === 'Qoala') {
          link = '/smartphone/policies/transaction-id/';
        } else {
          link = '/smartphone/policies/imeis/';
        }
      } else if (type === this.app.i18n.t('add_policy_option6')) {
        link = '/smartphone/policies/serial-number/';
      } else if (type === this.app.i18n.t('add_policy_option4')) {
        link = '/bus/policies/booking-code/';
      } else if (type === this.app.i18n.t('add_policy_option5')) {
        link = '/health/policies/dbd/search/';
      } else if (type === this.app.i18n.t('add_policy_option7')) {
        link = '/accomm/policies/search/';
      } else if (type === this.app.i18n.t('add_policy_car')) {
        link = '/policies/search/';
        pnr = pnr + '?productType=car&productCategory=vehicle';
      } else if (type === this.app.i18n.t('add_policy_motor')) {
        link = '/policies/search/';
        pnr = pnr + '?productType=motor&productCategory=vehicle';
      } else if (type === this.app.i18n.t('add_policy_motorvehicle')) {
        link = '/policies/search/';
        pnr = pnr + '?productCategory=vehicle';
      } else if (type === this.app.i18n.t('add_policy_personal_accident')) {
        link = '/policies/search/';
        pnr = pnr + '?productCategory=personal-accident';
      } else if (type === this.app.i18n.t('add_policy_property')) {
        link = '/policies/search/';
        pnr = pnr + '?productCategory=property';
      } else if (type === this.app.i18n.t('add_policy_health')) {
        link = '/policies/search/';
        pnr = pnr + '?productCategory=health';
      } else if (type === this.app.i18n.t('add_policy_hcp')) {
        link = '/policies/search/';
        pnr = pnr + '?productBenefit=hcp&productCategory=health';
      } else if (type === this.app.i18n.t('add_policy_life')) {
        link = '/policies/search/';
        pnr = pnr + '?productBenefit=TL&productCategory=life';
      } else if (type === this.app.i18n.t('add_policy_tdp')) {
        link = '/policies/search/';
        pnr = pnr + '?productBenefit=td&productCategory=health';
      } else if (type === this.app.i18n.t('add_policy_jdid_hcp')) {
        link = '/health/v1.0/policies/hcp/';
      } else if (type === this.app.i18n.t('add_policy_triasse_hcp')) {
        link = '/health/hcp/policies/insurance-number/';
      } else if (
        type === this.app.i18n.t('add_policy_momo_smartphone') ||
        type === this.app.i18n.t('add_policy_viet_smartphone')
      ) {
        link = '/smartphone/policies/imeis/';
      } else if (type === this.app.i18n.t('add_policy_traveloka_pa')) {
        link = '/health/v1.0/policies/pa+/';
      } else if (type === this.app.i18n.t('add_policy_jdid_pa')) {
        link = '/health/v1.0/policies/pa/';
      } else if (type === this.app.i18n.t('add_policy_bike')) {
        link = '/policies/search/';
        pnr = pnr + '?productType=bike&productCategory=vehicle';
      } else if (type === this.app.i18n.t('add_policy_xl_health')) {
        link = '/health/v1.0/policies/pa+/';
      } else {
        link = '/travel/policies/booking-code/';
      }
      const { data } = await this.$axios.get(
        link +
          pnr +
          (channel ? channel : '') +
          (partnerId ? partnerId : '') +
          (isCustomer ? isCustomer : '')
      );
      const response = data.data;
      callback(response);
    } catch (error) {
      console.log('error searchPnr', error);
    }
  },

  async searchTravelPolicy(context, { ...params }) {
    const urlData = {
      train: '/train/policies/booking-code/',
      default: '/travel/policies/booking-code/',
    };
    const url = urlData[params.type] || urlData['default'];
    return await this.$axios.get(`${url}${params.pnr}`);
  },

  async searchSmartphonePolicy(context, { ...params }) {
    const urlData = {
      transactionNumber: '/smartphone/policies/transaction-id/',
      default: '/smartphone/policies/imeis/',
    };
    const url = urlData[params.refType] || urlData['default'];
    return await this.$axios.get(`${url}${params.refNumber}`, {
      params: {
        type: params.type,
        partnerCode: params.partnerCode,
      },
    });
  },

  async searchHealthPolicy(context, { ...params }) {
    const urlData = {
      pa: '/health/v1.0/policies/pa/',
      'pa-plus': '/health/v1.0/policies/pa+/',
      hcp: '/health/v1.0/policies/hcp/',
      'tropical-disease': '/health/v1.0/policies/tropical-disease/',
      default: '/health/v1.0/policies/pa+/',
    };
    const url = urlData[params.type] || urlData['default'];

    try {
      const searchParams = new URLSearchParams();
      if (params.partnerId) {
        searchParams.append('partnerId', params.partnerId);
      }
      if (params.customer) {
        searchParams.append('customer', params.customer);
      }
      if (params.partnerCode) {
        searchParams.append('partnerCode', params.partnerCode);
      }
      const resSearchParam =
        Array.from(searchParams).length > 0 ? `?${searchParams}` : '';
      return await this.$axios.get(`${url}${params.pnr}${resSearchParam}`);
    } catch (error) {
      const { data } = error.response;
      if (data) {
        this.$sentry.withScope((scope) => {
          scope.setExtra('payload', params);
          scope.setExtra('response', data);
          this.$sentry.captureMessage(
            `ERROR SEARCH HEALTH POLICY, RES: ${JSON.stringify(
              data
            )} REQ: ${JSON.stringify(params)}`
          );
        });
      }
    }
  },

  async getClaimData(context, { page }) {
    try {
      const query = `orderBy=updatedAt&&sortDirection=desc&&limit=7&&page=${page}`;
      const { data } = await this.$axios.get(`/claims?${query}`);
      const response = data;
      const claims = context.getters['getClaims'];
      if (claims) {
        response.data = claims.data.concat(response.data);
        context.commit('updateClaims', response);
      } else {
        context.commit('updateClaims', response);
      }
      return response;
    } catch (error) {
      const errorResponse = error.response;
      const errorData = (errorResponse || {}).data;
      return errorData;
    }
  },
  async getClaimDetail(context, { typeClaim, number, callback, callbackFail }) {
    try {
      let link;
      if (typeClaim === 'train') {
        link = '/train/claims/';
      } else if (typeClaim === 'smartphone') {
        link = '/smartphone/claims/';
      } else if (typeClaim === 'bus') {
        link = '/bus/claims/';
      } else if (typeClaim === 'healthDBD') {
        link = '/health/claim/';
      } else if (typeClaim === 'accommodation') {
        link = '/accomm/claims/';
      } else if (typeClaim === 'electronic') {
        link = '/electronic/claims/';
      } else if (typeClaim === 'vehicle') {
        link = '/mv/claims/';
      } else if (typeClaim === 'hcp-partnership') {
        link = '/health/v1.0/claims/hcp/';
      } else if (typeClaim === 'pa+-partnership') {
        link = '/health/v1.0/claims/pa+/';
      } else if (typeClaim === 'accident-partnership') {
        link = '/health/v1.0/claims/pa/';
      } else if (typeClaim === 'tropical disease-partnership') {
        link = '/health/v1.0/claims/tropical-disease/';
      } else {
        link = '/travel/claims/';
      }
      const { data } = await this.$axios.get(link + number);
      const response = data.data;
      context.commit('updateClaim', response);
      return callback();
    } catch (error) {
      const errorResponse = error.response;
      let errorMessage;
      if (errorResponse) {
        errorMessage = errorResponse.data ? errorResponse.data.message : '';
      }
      if (callbackFail) {
        callbackFail(errorMessage || '');
      }
    }
  },
  async registerPolicy(context, { type, number, products }) {
    try {
      let link;
      let response;
      switch (type) {
        case this.app.i18n.t('add_policy_option1'):
          link = '/train/policies/register-policy';
          break;
        case this.app.i18n.t('add_policy_option3'):
        case this.app.i18n.t('add_policy_option6'):
        case this.app.i18n.t('add_policy_momo_smartphone'):
        case this.app.i18n.t('add_policy_viet_smartphone'):
          if (products) {
            link = '/electronic/policies/register';
            response = await this.$axios.post(link, products);
            return response.data;
          }
          link = '/smartphone/policies/add';
          break;
        case this.app.i18n.t('add_policy_option4'):
          link = '/bus/policies/register-policy';
          break;
        case this.app.i18n.t('add_policy_option5'):
          link = '/health/policies/add';
          break;
        case this.app.i18n.t('add_policy_option7'):
          link = '/accomm/policies/add';
          break;
        case this.app.i18n.t('add_policy_car'):
        case this.app.i18n.t('add_policy_motor'):
        case this.app.i18n.t('add_policy_health'):
        case this.app.i18n.t('add_policy_hcp'):
        case this.app.i18n.t('add_policy_life'):
        case this.app.i18n.t('add_policy_tdp'):
        case this.app.i18n.t('add_policy_bike'):
        case this.app.i18n.t('add_policy_property'):
        case this.app.i18n.t('add_policy_personal_accident'):
        case this.app.i18n.t('add_policy_motorvehicle'):
          link = '/policies/add';
          break;
        case this.app.i18n.t('add_policy_ovo_hcp'):
        case this.app.i18n.t('add_policy_bkk_hcp'):
        case this.app.i18n.t('add_policy_triasse_hcp'):
        case this.app.i18n.t('add_policy_jdid_hcp'):
        case this.app.i18n.t('add_policy_traveloka_pa'):
        case this.app.i18n.t('add_policy_jdid_pa'):
        case this.app.i18n.t('add_policy_xl_health'):
          link = '/health/v1.0/policies/register';
          break;
        case this.app.i18n.t('add_policy_traveloka_flight'):
          link = '/travel/policies/register-policy';
          break;
        default:
          link = '/travel/policies/register-policy';
          break;
      }
      const { data } = await this.$axios.post(link, {
        number,
      });
      return data;
    } catch (error) {
      const errorResponse = error.response;
      const errorData = (errorResponse || {}).data;
      return errorData.message;
    }
  },

  async registerTravelPolicy(context, { type, number }) {
    const urlData = {
      train: '/train/policies/register-policy/',
      default: '/travel/policies/register-policy/',
    };
    const url = urlData[type] || urlData['default'];
    return await this.$axios.post(`${url}`, {
      number,
    });
  },

  async registerSmartphonePolicy(context, { number }) {
    return await this.$axios.post(`/smartphone/policies/add`, {
      number,
    });
  },

  async registerHealthPolicy(context, { number }) {
    return await this.$axios.post(`/health/v1.0/policies/register`, {
      number,
    });
  },

  async submitClaim(context, { policyType, dataSend }) {
    const { CancelToken } = axios;
    const isProduction = process.env.NODE_ENV === 'production';
    const source = CancelToken.source();
    context.commit('updateCancelToken', source);
    let link;
    if (policyType === 'train') {
      link = '/train/v2/claims';
    } else if (policyType === 'smartphone') {
      // link = 'https://dev-alb.qoala.app:3010/api/claims/';
      link = 'https://api-staging.qoala.app/api/smartphone/v1/claims';
      isProduction && (link = 'https://api.qoala.app/api/smartphone/v1/claims');
    } else if (policyType === 'healthDBD') {
      link = 'https://dev-alb.qoala.app:3007/api/claim';
      isProduction && (link = 'https://dashboard.qoala.app:3007/api/claim');
    } else if (policyType === 'bus') {
      link = '/bus/claims/';
    } else if (policyType === 'accommodation') {
      link = `${process.env.API_BASE_URL}/accomm/claims`;
    } else if (policyType === 'vehicle') {
      link = 'https://api-staging.qoala.app/api/mv/claims';
    } else if (policyType === 'travelokaAccom') {
      link = '/travel/v1/claims';
    } else {
      link = '/travel/v1/claims';
    }
    try {
      const { data } = await this.$axios.post(link, dataSend, {
        timeout: Infinity,
        cancelToken: source.token,
      });
      if (policyType === 'smartphone') {
        return data.data;
      }
      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        if (data) {
          context.setSentryClaimError(dataSend, data);
        }
      }
      throw error;
    }
  },
  async updateDocument(
    context,
    { typeClaim, dataSend, callback, callbackFail, claimNumber }
  ) {
    let link;
    if (typeClaim === 'train') {
      link = '/train/claims/';
    } else if (typeClaim === 'smartphone') {
      link = '/smartphone/claims/';
    } else if (typeClaim === 'bus') {
      link = '/bus/claims/';
    } else if (typeClaim === 'healthDBD') {
      link = '/dbd/claims/';
    } else if (typeClaim === 'accommodation') {
      link = '/accomm/claims/' + claimNumber;
    } else if (typeClaim === 'vehicle') {
      link = '/mv/claims/' + claimNumber;
    } else {
      link = '/travel/claims/';
    }
    const userToken = context.rootGetters['user/getUserToken'];
    const header = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    try {
      const { data } = await axios.put(
        config.get('0bdc570e1a19c5f1bacb5fe5acf6ed90') + link,
        dataSend,
        header
      );
      const response = data.data;
      return callback(response);
    } catch (error) {
      return callbackFail(error);
    }
  },
  async getSmartPhoneProduct(context, params) {
    const token = context.rootGetters['user/getUserToken'];
    try {
      let data;
      if (token) {
        data = await this.$axios.post('/smartphone/check-product', params);
      } else {
        data = await axios.post(
          `${process.env.API_BASE_URL}/smartphone/check-product`,
          params
        );
      }
      const response = data.data.data;
      context.commit('updateSmartPhoneProduct', response);
      return response;
    } catch (error) {
      const errorResponse = error.response;
      return errorResponse;
    }
  },
  async purchasePolicy(context, params) {
    const token = context.rootGetters['user/getUserToken'];
    const paramsPrepare = {
      full_name: params.full_name,
      email: params.email,
      phone_number: params.phone_number,
      product_code: params.product_code,
    };
    const paramsPurchase = {
      payment_method: params.payment_method,
      product_code: params.product_code,
      type: params.type,
      identifier: params.identifier,
      promo_codes: params.promoCodes,
    };
    if (params.transactionId) {
      paramsPurchase.transaction_id = params.transactionId;
    }
    try {
      let data;
      if (token) {
        data = await this.$axios.post(
          '/smartphone/prepare-customer',
          paramsPrepare
        );
      } else {
        data = await axios.post(
          `${process.env.API_BASE_URL}/smartphone/prepare-customer`,
          paramsPrepare
        );
      }
      if (data) {
        const token = data.data.data.token;
        try {
          let data;
          let config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          data = await this.$axios.post(
            '/smartphone/purchase',
            paramsPurchase,
            config
          );
          const response = data.data.data;
          context.commit('updatePurchasePayment', response);
          return response;
        } catch (error) {
          const { data } = error.response;
          if (data) {
            this.$sentry.withScope((scope) => {
              scope.setExtra('payload', params);
              scope.setExtra('response', data);
              this.$sentry.captureMessage(
                `ERROR POLICY CREATION RES: ${JSON.stringify(
                  data
                )} REQ: ${JSON.stringify(params)}`
              );
            });
          }
          throw error;
        }
      }
    } catch (err) {
      this.$sentry.withScope((scope) => {
        scope.setExtra('payload', paramsPrepare);
        scope.setExtra('response', err.response);
        this.$sentry.captureMessage(
          `ERROR SMP PREPARE CUSTOMER: ${JSON.stringify(
            err.response
          )} REQ: ${JSON.stringify(paramsPrepare)}`
        );
      });

      console.log(err);
      throw err;
    }
  },
  async getDocumentPresignLink(context, payload) {
    try {
      const response = await this.$axios.post(
        '/accomm/documents/get-upload-url',
        payload
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async getDocumentPresignLinkSmartphone(context, payload) {
    try {
      const response = await this.$axios.post(
        '/smartphone/v1/claims/presigned?type=claims',
        payload
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async getBusPresignLink(context, payload) {
    try {
      const response = await this.$axios.post('/bus/claims/presigned', payload);
      return response.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  setSentryClaimError(payload, data) {
    this.$sentry.withScope((scope) => {
      scope.setExtra('payload', payload);
      scope.setExtra('response', data);
      this.$sentry.captureMessage(`ERROR CLAIM  ${JSON.stringify(data)}`);
    });
  },
  async submitBusClaim(context, payload) {
    try {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      context.commit('updateCancelToken', source);
      const { data } = await this.$axios.post(`/bus/claims`, payload, {
        timeout: Infinity,
        cancelToken: source.token,
      });
      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        if (data) {
          context.setSentryClaimError(payload, data);
        }
      }
      throw error;
    }
  },
  async documentsResubmitPresign(context, payload) {
    try {
      const response = await this.$axios.post('bus/claim-histories/presigned', {
        resubmitDocuments: payload,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async resubmitBusClaim(context, { payload, number }) {
    try {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      context.commit('updateCancelToken', source);
      const { data } = await this.$axios.post(
        `/bus/claim-histories/${number}`,
        payload,
        {
          timeout: Infinity,
          cancelToken: source.token,
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  async createPolicy(context, payload) {
    try {
      const data = await this.$axios.post('/policies', payload);
      return data.data.data;
    } catch (error) {
      const countryCodeLowCase = context.rootGetters['core/getCountryCode'];
      const countryCode = countryCodeLowCase?.toUpperCase() || '';
      this.$sentry.withScope((scope) => {
        scope.setExtra('payload', JSON.stringify(payload));
        scope.setExtra('error', JSON.stringify(error));
        this.$sentry.captureMessage(
          `[QAPP-${countryCode}] Failed to Create Policy`
        );
      });
      throw error;
    }
  },
  async getPolicyList(context, { page, query }) {
    try {
      const { data } = await this.$axios.get(
        `${process.env.API_BASE_URL}/policies/list?descending=true&limit=20&page=${page}&${query}`
      );
      const response = data;
      const policies = context.getters['getPolicyList'];
      context.commit('updatePolicyList', response);

      if (policies) {
        if (response === policies) return true;
        if (response.meta.page > policies.meta.page) {
          // Copy Response data for avoiding error vuex mutate
          const newResponse = JSON.parse(JSON.stringify(response));
          newResponse.data = [...policies.data, ...response.data];
          context.commit('updatePolicyList', newResponse);
        }
      }
      return response;
    } catch (err) {
      return err;
    }
  },
  async getHCPPartnershipPresignLink(context, payload) {
    try {
      const response = await this.$axios.post('/health/hcp/claims/presigned', {
        documents: payload,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async submitHCPPartnershipClaim(context, payload) {
    try {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      context.commit('updateCancelToken', source);
      const { data } = await this.$axios.post(
        `/health/v1.0/claims/hcp`,
        payload,
        {
          timeout: Infinity,
          cancelToken: source.token,
        }
      );
      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        if (data) {
          context.setSentryClaimError(payload, data);
        }
      }
      throw error;
    }
  },
  async resubmitHCPPartertnsipClaim(context, { payload, number }) {
    try {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      context.commit('updateCancelToken', source);
      const { data } = await this.$axios.put(
        `/health/v1.0/claims/hcp/${number}`,
        payload,
        {
          timeout: Infinity,
          cancelToken: source.token,
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  async resubmitPAPLUSPartertnsipClaim(context, { payload, number }) {
    try {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      context.commit('updateCancelToken', source);
      const { data } = await this.$axios.put(
        `/health/v1.0/claims/pa+/${number}`,
        payload,
        {
          timeout: Infinity,
          cancelToken: source.token,
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  async submitPAPLUSPartnershipClaim(context, payload) {
    try {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      context.commit('updateCancelToken', source);
      const { data } = await this.$axios.post(
        `/health/v1.0/claims/pa+`,
        payload,
        {
          timeout: Infinity,
          cancelToken: source.token,
        }
      );
      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        if (data) {
          context.setSentryClaimError(payload, data);
        }
      }
      throw error;
    }
  },
  async submitPAPartnershipClaim(context, payload) {
    try {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      context.commit('updateCancelToken', source);
      const { data } = await this.$axios.post(
        `/health/v1.0/claims/pa`,
        payload,
        {
          timeout: Infinity,
          cancelToken: source.token,
        }
      );
      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        if (data)
          this.$sentry.captureMessage(`ERROR CLAIM  ${JSON.stringify(data)}`);
      }
      throw error;
    }
  },
  async submitTropicalDiseasePartnershipClaim(context, payload) {
    try {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      context.commit('updateCancelToken', source);
      const { data } = await this.$axios.post(
        `/health/v1.0/claims/tropical-disease`,
        payload,
        {
          timeout: Infinity,
          cancelToken: source.token,
        }
      );
      return data;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        if (data) {
          this.$sentry.withScope((scope) => {
            scope.setExtra('payload', payload);
            scope.setExtra('response', data);
            this.$sentry.captureMessage(
              `ERROR CLAIM TD  ${JSON.stringify(data)}`
            );
          });
        }
      }
      throw error;
    }
  },
  async checkPolicyList(context, { identifier, codeCountry = 'ID' }) {
    const endpoint = `/policies/check?identifier=${identifier}&country=${codeCountry}`;
    try {
      const { data } = await this.$axios.get(endpoint);
      const response = data.data;
      return response;
    } catch (error) {
      throw error;
    }
  },
  async addBulkPolicy(context, { identifier }) {
    try {
      const { data } = await this.$axios.post('/policies/add/bulk', {
        identifier,
      });
      const response = data.data;
      return response;
    } catch (error) {
      const errorResponse = error.response;
      return errorResponse;
    }
  },
  async validateLimitPolicy(context, { payload }) {
    try {
      return await axios.post(
        `${process.env.API_BASE_URL}/policies/validate`,
        payload
      );
    } catch (error) {
      throw error;
    }
  },
  async updatePolicyDocument(context, { payload, transactionNumber, product }) {
    const userToken = context.rootGetters['user/getUserToken'];
    const header = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    try {
      const response = await axios.post(
        `${process.env.API_BASE_URL}/policies/${product}/${transactionNumber}/update-policy`,
        payload,
        header
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },
  async claimDetailV2(context, payload) {
    try {
      const { data } = await this.$axios.get('/claims/v2/details', payload);
      const claimDetail = data.data;
      context.commit('updateClaim', claimDetail);
    } catch (error) {
      throw error;
    }
  },
  async getInsuredListPaPlus(context, payload) {
    try {
      let link = `/health/v1.0/claims/pa/check`;
      let response = await this.$axios.post(link, payload);
      const { data } = response.data;
      context.commit('updateInsuredListPaPlus', data);
      return data;
    } catch (error) {
      return error.response;
    }
  },
  async presignTrainDocuments(ctx, payload) {
    try {
      const response = await this.$axios.post('/train/v2/documents', payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async getQoalaInitiatedPolicy(context, payload) {
    try {
      const response = await axios.get(
        `${process.env.API_BASE_URL}/travel/policies/${payload.number}?policy-guid=${payload.guid}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },
  async getQoalaInitiatedBankList(context, payload) {
    try {
      const partner = payload.query.partner
        ? `&partner=${payload.query.partner}`
        : '';
      const response = await this.$axios.get(
        `/flight/v2/banks?country=${payload.query.country.toLowerCase()}${partner}`
      );
      context.commit('setQoalaInitiatedBankList', response.data);
    } catch (error) {
      return error;
    }
  },
  async qoalaInitiatedClaim(context, payload) {
    try {
      return await axios.post(
        `${process.env.API_BASE_URL}/travel/v1/claims?policy-guid=${payload.guid}`,
        payload.data
      );
    } catch (error) {
      return error.response;
    }
  },
  /** Get Smartphone Policy Detail */
  async getSmartphonePolicyDetail(context, { policyNumber }) {
    try {
      const link = `${process.env.API_BASE_URL}/smartphone/policies/partner-transaction-id/${policyNumber}`;
      const header = {
        headers: {
          'x-api-key': `${process.env.SMARTPHONE_SVC_API_KEY}`,
        },
      };
      const { data } = await axios.get(link, header);
      const response = data && data.data ? data.data : data;
      context.commit('updatePolicy', response);
      return response;
    } catch (error) {
      throw error;
    }
  },
  /** Get Smartphone Claim Detail */
  async getSmartphoneClaimDetail(context, { claimNumber }) {
    try {
      const link = `${process.env.API_BASE_URL}/smartphone/claims/${claimNumber}`;
      const header = {
        headers: {
          'x-api-key': `${process.env.SMARTPHONE_SVC_API_KEY}`,
        },
      };
      const { data } = await axios.get(link, header);
      const response = data && data.data ? data.data : data;
      context.commit('updateClaim', response);
      return response;
    } catch (error) {
      throw error;
    }
  },
  /** Submit External Claim */
  async externalSubmitClaim(context, { dataSend }) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    context.commit('updateCancelToken', source);
    const link = `${process.env.API_BASE_URL}/smartphone/v1/claims`;
    const header = {
      headers: {
        'x-api-key': `${process.env.SMARTPHONE_SVC_API_KEY}`,
      },
    };
    try {
      const { data } = await axios.post(link, dataSend, header, {
        timeout: Infinity,
        cancelToken: source.token,
      });
      return data.data;
    } catch (error) {
      const { data } = error.response;
      if (data) {
        this.$sentry.withScope((scope) => {
          scope.setExtra('payload', dataSend);
          scope.setExtra('response', data);
          this.$sentry.captureMessage(
            `[CLAIM_EXTERNAL_MOMO] RES: ${JSON.stringify(
              data
            )} REQ: ${JSON.stringify(dataSend)}`
          );
        });
      }
      throw error.response;
    }
  },
};
