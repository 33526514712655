import axios from 'axios';
import formatMoney from '@/utils/common/formatMoney';
import formatMoneyWithCent from '@/utils/common/formatMoneyWithCent';
import isShowDocumentClaim from '@/utils/claim/isShowDocumentClaim';
import claimExtendedConfig from '@/utils/claim/claimExtendedConfig';
import getFilteredDocumentList from '@/utils/claim/getFilteredDocumentList';
import getFormatMoneyWithCurrencyCode from '@/utils/common/getFormatMoneyWithCurrencyCode';
import currenciesToNumber from '~/utils/common/currenciesToNumber';
import capitalize from '~/utils/common/capitalize';
import validateEmail from '~/utils/common/validateEmail';
import translateAirtable from '~/utils/common/translateAirtable';
import isEmpty from '~/utils/common/isEmpty';
import healthMap from '~/utils/common/healthMap';
import lifeMap from '~/utils/common/lifeMap';
import mapDocuments from '~/utils/common/mapDocuments';
import mapAccessories from '~/utils/common/mapAccessories';
import isAvailablePromo from '~/utils/common/isAvailablePromo';
import toSnakeCase from '~/utils/common/toSnakeCase';
import bindDragListener from '~/utils/common/bindDragListener';

const isClient = process.client && typeof window !== 'undefined';

export const helper = (app) => ({
  isShowDocumentClaim,
  claimExtendedConfig,
  getFilteredDocumentList,
  formatMoney,
  formatMoneyWithCent,
  getFormatMoneyWithCurrencyCode,
  currenciesToNumber,
  capitalize,
  validateEmail,
  translateAirtable,
  isEmpty,
  healthMap,
  lifeMap,
  mapDocuments,
  mapAccessories,
  isAvailablePromo,
  toSnakeCase,
  bindDragListener,

  goLogin() {
    app.router.push(app.localePath('account'));
  },

  goBack(customDestination) {
    if (window.history.length <= 2) {
      app.router.replace(app.localePath(customDestination));
    } else {
      app.router.go(-1);
    }
  },

  goRegister() {
    app.$analytics.pushEvent({
      event: 'registerpage-landing',
      eventCategory: 'Register',
      eventAction: 'registerpage-landing',
    });
    app.router.push(app.localePath('sessions-register'));
  },

  setupWebViewJavascriptBridge(callback) {
    if (!isClient) {
      return null;
    }
    if (window.WebViewJavascriptBridge) {
      return callback(window.WebViewJavascriptBridge);
    }
    if (window.WVJBCallbacks) {
      return window.WVJBCallbacks.push(callback);
    }
    window.WVJBCallbacks = [callback];
    const WVJBIframe = document.createElement('iframe');
    WVJBIframe.style.display = 'none';
    WVJBIframe.src = 'https://__bridge_loaded__';
    document.documentElement.appendChild(WVJBIframe);
    setTimeout(() => {
      document.documentElement.removeChild(WVJBIframe);
    }, 0);
    return true;
  },

  redirectUrl(url) {
    if (!isClient) {
      return null;
    }
    // eslint-disable-next-line
    if (window.isAndroid) {
      qoala.payStripe(url); // eslint-disable-line
    } else if (window.isApple) {
      if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.qoala
      ) {
        window.webkit.messageHandlers.qoala.postMessage({
          action: 'payStripe',
          content: url,
        });
      }
    } else {
      window.open(url, '_blank');
    }
    return null;
  },

  async uploadMvDocument(document) {
    const documents = document;
    const docsTemp = [];
    documents.forEach((doc) => {
      const type = doc.fileType.split('/')[1];
      const data = {
        documentable: 'policy',
        filename: doc.key + '.' + type,
        type: doc.key,
        contentType: doc.fileType,
      };
      docsTemp.push(this.uploadDocument(data, documents));
    });
    const docs = await Promise.all(docsTemp);
    return mapDocuments(docs);
  },

  async uploadDocument(dataFile, documents) {
    const responseData = await app.store.dispatch(
      'vehicle/getDocumentPresignLink',
      dataFile
    );
    if (responseData) {
      const { data } = responseData;
      const file = documents.find((document) => document.key === dataFile.type);
      data.key = dataFile.type;
      const dataBase64 = file.data.split(';base64,');
      const dataSplitBase64 = dataBase64[1];
      const buffer = Buffer.from(dataSplitBase64, 'base64');
      if (file) {
        try {
          await axios.put(data.presignedUrl, buffer);
          return data;
        } catch (error) {
          return false;
        }
      }
    }
    return true;
  },

  async getMappedDocument(fileId) {
    let filename = '';
    if (fileId) {
      const type = fileId.fileType.split('/')[1];
      filename = `id-card.${type}`;
    } else {
      const user = app.store.getters['user/getUserData'];
      const ktp = user.documents.find((document) => document.type === 'ktp');
      filename = ktp.filename;
    }
    const dotSplit = filename.split('.');
    const fileType = dotSplit[dotSplit.length - 1];
    const dataFile = {
      documentable: 'policy',
      filename: `id-card.${fileType}`,
      type: 'id-card',
    };
    const responseData = await app.store.dispatch(
      'vehicle/getDocumentPresignLink',
      dataFile
    );
    if (responseData) {
      const { data } = responseData;
      data.key = dataFile.type;
      if (fileId) {
        const dataBase64 = fileId.data.split(';base64,');
        const dataSplitBase64 = dataBase64[1];
        const buffer = Buffer.from(dataSplitBase64, 'base64');
        try {
          await axios.put(data.presignedUrl, buffer);
          const document = {
            filename: data.filename,
            url: data.url,
            type: data.key,
          };
          return document;
        } catch (error) {
          return false;
        }
      } else {
        const user = app.store.getters['user/getUserData'];
        const ktp = user.documents.find((document) => document.type === 'ktp');
        const dataSplitBase64 = await app.store.dispatch(
          'user/getDocument',
          ktp.filename
        );
        const buffer = Buffer.from(dataSplitBase64, 'base64');
        try {
          await axios.put(data.presignedUrl, buffer);
          const document = {
            filename: data.filename,
            url: data.url,
            type: data.key,
          };
          return document;
        } catch (error) {
          return false;
        }
      }
    }
    return true;
  },

  openPdf(url) {
    // eslint-disable-next-line
    if (window.isAndroid && qoala?.openBrowser instanceof Function) {
      return qoala.openBrowser(url); // eslint-disable-line
    }
    if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.qoala
    ) {
      return window.webkit.messageHandlers.qoala.postMessage({
        action: 'openPdf',
        content: url,
      });
    }
    return window.open(url, '_blank');
  },

  openNewTab(url) {
    // eslint-disable-next-line
    if (window.isAndroid && qoala?.openBrowser instanceof Function) {
      return qoala.openBrowser(url); // eslint-disable-line
    }
    if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.qoala
    ) {
      return window.webkit.messageHandlers.qoala.postMessage({
        action: 'openBrowser',
        content: url,
      });
    }
    return window.open(url, '_blank');
  },
  generateCaptchaHeader(recaptchaToken) {
    return {
      headers: {
        'X-Captcha-Token': recaptchaToken,
      }
    }
  }
});

export default ({ app }, inject) => {
  inject('helper', helper(app));
};
