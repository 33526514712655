import axios from 'axios';
import qoalaStorage from '~/core/Storage';
import moment from 'moment';
import Cookies from 'js-cookie';
import { parseIfJsonString } from '~/core/helper/JSONHelper';

export default {
  async registerUser(state, { payload, config }) {
    try {
      const { data } = await this.$axios.post(
        '/registrations',
        payload,
        config
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  async userRating(state, { ratesSend, callbackSuccess }) {
    try {
      await this.$axios.post('/rates', ratesSend);
      callbackSuccess('', 'Terimakasih atas penilaian dan saran anda.');
    } catch (error) {
      console.log(error);
    }
  },
  async activateUser(context, { datas, config }) {
    const { data } = await this.$axios.put(
      '/registrations/check-token',
      {
        phoneNumber: datas.phoneNumber,
        token: datas.token,
      },
      config
    );
    const response = data.data;
    this.$analytics.pushEvent({
      event: 'Register',
      eventAction: 'Flow',
      eventLabel: 'Register-Success',
    });
    return response;
  },
  async resendActivationReg(context, { datas, config }) {
    try {
      const response = await this.$axios.post(
        '/registrations/resend-otp-token',
        datas,
        config
      );
      if (response.status !== 200) {
        throw response;
      }
    } catch (error) {
      const errorResponse = error.response;
      const errorData = errorResponse.data;
      throw errorData;
    }
  },
  async getProfile(context, { callback, callbackFail }) {
    // WHITE LABEL USER IS NOT ALLOWED TO FETCH USER PROFILE
    if (context.state.userData?.partnerCode) {
      return;
    } 
    try {
      const { data } = await this.$axios.get('/users/profile');
      const response = data.data;
      context.commit('updateDataUser', response);
      if (callback) {
        callback();
      }
    } catch (error) {
      const errorResponse = error.response;
      // Handling when error response is undefined
      let errorMessage;
      if (errorResponse) {
        errorMessage = errorResponse.data ? errorResponse.data.message : '';
      }
      // When I find 'user/getProfile', callbackFail is not available
      if (callbackFail) {
        callbackFail(errorMessage || '');
      }
    }
  },
  async requestLoginOtp(context, phone) {
    try {
      const { data } = await this.$axios.post('/sessions/request-login-otp', {
        phoneNumber: phone,
      });
      return data.data;
    } catch (err) {
      throw err;
    }
  },
  async validatePhone(context, { credentials, config }) {
    try {
      const { data } = await this.$axios.post(
        '/sessions/check-user',
        credentials,
        config
      );
      return data.data;
    } catch (error) {
      return error;
    }
  },

  async login(context, { credentials, config }) {
    try {
      const { data } = await this.$axios.post('/sessions', credentials, config);
      const response = data.data;
      context.commit('updateData', response);
      Cookies.remove('guest');
      this.$axios.setToken(response.token, 'Bearer');
      context.dispatch('intervalToken', {
        refresh: response.refresh,
      });
      this.$gtm.push({
        event: 'setUserId',
        userId: response.user.id,
      });
      this.$analytics.pushEvent(
        {
          event: 'Login-Page',
          eventCategory: 'User',
          eventAction: 'Login',
          eventLabel: response.user.fullName + ' ' + response.user.email,
        },
        {
          event: 'Login-Page',
          eventCategory: 'Login',
          eventAction: 'Login-Page',
          eventLabel: response.user.fullName + ' ' + response.user.email,
        }
      );
      window?.errorHandler?.setUser(response.user.email);
      window.initChat(response.user);
      const dataTags = {
        Identity: response.user.uuid,
        email: response.user.email,
      };
      if (window.isAndroid) {
        // eslint-disable-next-line
        console.log(qoala, response);
        // eslint-disable-next-line
        if (qoala.sendTags) {
          // eslint-disable-next-line
          qoala.sendTags(JSON.stringify(dataTags));
        }
      } else if (window.isApple) {
        window.setupWebViewJavascriptBridge((bridge) => {
          bridge.callHandler(
            'appleTagUser',
            {
              dataTags,
            },
            (responseData) => {
              console.log('JS appleTagUser response', responseData);
            }
          );
          bridge.callHandler(
            'appleInitFreshchat',
            {
              externalId: response.user.email,
              restoreId: response.user.chattingId,
            },
            (responseData) => {
              console.log('JS appleInitFreshchat response', responseData);
            }
          );
        });
        if (
          window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers.qoala
        ) {
          window.webkit.messageHandlers.qoala.postMessage({
            action: 'sendTags',
            content: dataTags,
          });
        }
      }
      if (window.clevertap) {
        const dataLogin = {
          Name: response.user.fullName, // String
          Identity: response.user.uuid, // String or number
          Email: response.user.email, // Email address of the user
          Phone: response.user.phoneNumber, // Phone (with the country code)
          RegistrationStartDate: moment(response.user.createdAt).format(
            'DD/MM/YYYY'
          ),
          // Gender: response.user,                     // Can be either M or F
          // DOB: response.user,                 // Date of Birth. Date object

          // optional fields. controls whether the user will be sent email, push etc.
          'MSG-email': true, // Disable email notifications
          'MSG-push': true, // Enable push notifications
          'MSG-sms': true, // Enable sms notifications
          'MSG-whatsapp': true, // Enable WhatsApp notifications
        };
        if (window.androidCleverTap) {
          // Call Android interface
          window.androidCleverTap.pushProfile(JSON.stringify(dataLogin));
        } else if (
          window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers.iosCleverTap
        ) {
          const message = { action: 'profilePush', properties: dataLogin };
          window.webkit.messageHandlers.iosCleverTap.postMessage(message);
        } else {
          window.clevertap.onUserLogin.push({
            Site: dataLogin,
          });
        }
      }
      return response;
    } catch (error) {
      const errorResponse = error.response;
      const errorData = errorResponse.data;
      return errorData;
    }
  },
  async refreshToken(context, { credentials, callback }) {
    try {
      const { data } = await this.$axios.post(
        '/sessions/refresh',
        credentials,
        {
          progress: false,
        }
      );
      const response = data.data;
      this.$axios.setToken(response.token, 'Bearer');
      context.commit('updateData', response);
      const refresh = context.getters['getUserTokenRefresh'];
      if (refresh) context.commit('clearRefreshToken');
      callback();
      context.dispatch('intervalToken', {
        refresh: response.refresh,
      });
    } catch (error) {
      if (error.response) context.dispatch('logout');
    }
  },
  intervalToken(context, dataRefresh) {
    const TIMEOUT = 1000 * 3600 * 48;
    context.commit(
      'updateRefreshToken',
      setTimeout(() => {
        context.dispatch('refreshToken', {
          credentials: dataRefresh,
          callback: () => {},
        });
      }, TIMEOUT)
    );
  },
  async updateUser(context, datas) {
    const refresh = context.getters['getUserTokenRefresh'];
    let noRefresh = false;
    if (datas.noRefresh === true) {
      noRefresh = true;
      delete datas.noRefresh;
    }
    try {
      const { data } = await this.$axios.put('/users/update', datas);
      const response = data.data;
      if (noRefresh === false) {
        await context.dispatch('refreshToken', {
          credentials: {
            refresh,
          },
          callback: () => {},
        });
      }

      context.commit('updateDataUser', response);
      return response;
    } catch (error) {
      const errorResponse = error.response;
      const errorData = errorResponse ? errorResponse.data : {};
      throw errorData;
    }
  },
  async requestOTP(state, { datas, config }) {
    const { data } = await this.$axios.post(
      '/registrations/reset-security-code',
      datas,
      config
    );
    const response = data.data;
    return response;
  },
  async verifyData(context, datas) {
    const { data } = await this.$axios.post('/verifications', datas);
    const response = data.data;
    return response;
  },
  async checkOTPStatus(state, { datas, config }) {
    const { data } = await this.$axios.post(
      '/registrations/check-security-otp-code',
      datas,
      config
    );
    const response = data.data;
    return response;
  },
  async verifyOTP(state, datas) {
    const { data } = await this.$axios.post(
      '/verifications/check-token',
      datas
    );
    const response = data.data;
    this.$analytics.pushEvent({
      event: 'Account-Login',
      eventLabel: 'Account-Page-Login-Email-Verified',
    });
    return response;
  },
  async resetPassword(state, datas) {
    const data = await this.$axios.post(
      '/registrations/confirm-new-security-code',
      datas
    );
    const response = data.data;
    return response;
  },
  async getNotifications(context, { page, callback }) {
    try {
      const { data } = await this.$axios.get(
        `/notifications?limit=12&&page=${page}&descending=true`
      );
      const response = data.data;
      const respWithMeta = data;
      context.commit('updateNotificationsList', response);
      callback(respWithMeta);
    } catch (error) {
      const errorResponse = error.response;
      const errorData = errorResponse ? errorResponse.data : null;
      return errorData;
    }
  },
  async getUnreadNotifications(context, { callback, callbackFail }) {
    try {
      const { data } = await this.$axios.get('/notifications/unread');
      const response = data.data;
      callback(response);
    } catch (error) {
      const errorResponse = error.response;
      const errorData = errorResponse.data;
      callbackFail(errorData.message);
    }
  },
  async readNotification(context, notifId) {
    try {
      const { data } = await this.$axios.put(`/notifications/${notifId}`, {
        read: true,
      });
      const response = data.data;
      context.commit('updateNotifications', response);
      return response;
    } catch (error) {
      const errorResponse = error.response;
      const errorData = errorResponse.data;
      return errorData;
    }
  },
  async searchAccountBank(context, datas) {
    try {
      const { data } = await axios.post(
        'https://api.qoala.app/api/kyc/bank-inquiry',
        datas
      );
      const response = data.data;
      return response;
    } catch (err) {
      return err;
    }
  },
  logout(context) {
    if (window.isAndroid) {
      // eslint-disable-next-line
      qoala.untagUser();
    } else {
      if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.qoala
      ) {
        window.webkit.messageHandlers.qoala.postMessage({
          action: 'deleteTags',
          content: ['email'],
        });
      }
    }
    if (window.fcWidget) {
      window.fcWidget.destroy();
    }
    this.$axios.setToken(false);
    const tempUtmParams = qoalaStorage.getItem('utmParams');
    qoalaStorage.clear();
    context.commit('clearState');
    context.commit('removeToken');
    context.commit('clearRefreshToken');
    this.$gtm.push({
      event: 'setUserId',
      userId: 'Guest',
    });
    qoalaStorage.setItem('utmParams', tempUtmParams);
    context.commit('general/setUtmParams', parseIfJsonString(tempUtmParams));
  },
  async checkKTP(context, ktp) {
    try {
      const { data } = await axios.post(
        `${process.env.API_BASE_URL}/kyc/check-ktp`,
        ktp
      );
      const response = data.data;
      return response;
    } catch (err) {
      return err;
    }
  },
  async getDocument(context, fileName) {
    try {
      const baseUrl = `${process.env.API_BASE_URL}/`;
      const urlReplace = baseUrl.replace('/api/', '/v1/');
      const dataBase64 = await this.$axios.get(
        `${urlReplace}documents/${fileName}`
      );
      return dataBase64.data;
    } catch (err) {
      return err;
    }
  },
  async profileAssessment(context, payload) {
    const { data } = await this.$axios.post(
      '/users/profile/assessment',
      payload
    );
    context.commit('setProfile', payload);
    const response = data.data;
    return response;
  },
  async getProfileAssessment(context) {
    const { data } = await this.$axios.get('/users/profile/assessment');
    const response = data.data.data;
    context.commit('setProfile', response);
    return response;
  },
  // Create Session External V2
  async createSessionExternalV2(context, payload) {
    try {
      const header = {
        headers: {
          'x-api-key': payload.publicKey,
        },
      };
      const payloadSent = {
        token: payload.token,
      };
      const { data } = await axios.post(
        `${process.env.API_BASE_URL}/sessions/external`,
        payloadSent,
        header
      );

      context.commit('updateData', data.data);
      this.$axios.setToken(data.data.token, 'Bearer');
      return data;
    } catch (error) {
      throw error;
    }
  },
  clearTokenExternal(context) {
    context.commit('removeTokenExternal');
    context.commit('clearRefreshToken');
  },
};
